import gql from "graphql-tag"

const USER_BY_PASSWORD_RESET = gql`
  query UserByPasswordReset($token: String!) {
    userByPasswordReset(token: $token) {
      id
    }
  }
`

export default USER_BY_PASSWORD_RESET
