import React, { useState } from "react"
import * as Yup from "yup"
import { useMutation } from "@apollo/react-hooks"
import { withFormik, Field } from "formik"
import { get } from "lodash/fp"
import { Box, TextArea, Button, Text, MaskedInput } from "grommet"
import FieldInputWithErrors from "./FieldInputWithErrors"

import SET_MEMBER_PASSWORD from "../queries/setMemberPassword"

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
})

async function handleSubmit(
  setMemberPassword,
  payload,
  { createProResult, setSubmitting, setStatus },
  setApiErrors,
  data,
  errors
) {
  setApiErrors([])
  try {
    const result = await setMemberPassword({ variables: payload })
    if (get("data.setMemberPassword.error", result)) {
      setApiErrors([get("data.setMemberPassword.error", result)])
    } else {
      window.location.href = get("data.setMemberPassword.multipass_url", result)
    }
  } catch (e) {
    setSubmitting(false)
    setApiErrors([{ message: String(e) }])
  }
}

const FormResetPassword = ({
  handleChange,
  handleBlur,
  onChange,
  errors,
  touched,
  isValid,
  values,
  referral_fetched,
  ...formikProps
}) => {
  const [apiErrors, setApiErrors] = useState([])
  const [setMemberPassword, { data }] = useMutation(SET_MEMBER_PASSWORD)

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit(setMemberPassword, values, formikProps, setApiErrors, data)
      }}
      onReset={formikProps.handleReset}
    >
      <Box gap="small" margin={{ bottom: "medium", top: "small" }}>
        <div>
          <Field
            component={FieldInputWithErrors}
            name="password"
            type="password"
            placeholder="Password"
          />
        </div>
        <div>
          <Field
            component={FieldInputWithErrors}
            name="passwordConfirmation"
            type="password"
            placeholder="Password Confirmation"
          />
        </div>
        {apiErrors.length > 0 &&
          apiErrors.map((e, i) => (
            <div key={i}>
              <Text color="status-critical">{e.message}</Text>
            </div>
          ))}
        <Box direction="row-responsive" margin={{ vertical: "medium" }}>
          <Button
            disabled={!isValid}
            label="Set Password"
            type="submit"
            primary
            style={{ minWidth: "200px" }}
          />
        </Box>
        <Field type="hidden" name="referred_by" />
      </Box>
    </form>
  )
}

export default withFormik({
  validationSchema: PasswordSchema,
  handleSubmit: handleSubmit,
  displayName: "Set Password",
  mapPropsToValues: props => ({
    password: "",
    passwordConfirmation: "",
    resetPasswordToken: props.resetPasswordToken,
  }),
})(FormResetPassword)
