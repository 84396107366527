import gql from "graphql-tag"

const SET_MEMBER_PASSWORD = gql`
  mutation SetMemberPassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    setMemberPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      multipass_url
      error {
        message
      }
    }
  }
`

export default SET_MEMBER_PASSWORD
