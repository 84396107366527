import React from "react"
import { Box, Heading, Paragraph } from "grommet"

import { useQuery } from "@apollo/react-hooks"
import { get } from "lodash/fp"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormResetPassword from "../../components/FormResetPassword"

import USER_BY_PASSWORD_RESET from "../../queries/userByPasswordReset"

import withLocation from "../../components/withLocation"

const SetPasswordPage = ({ search }) => {
  const { firstName, token, reset } = search
  const { loading } = useQuery(USER_BY_PASSWORD_RESET, {
    variables: { token },
    onCompleted: x => {
      const userId = get("userByPasswordReset.id", x)
      if (!userId) {
        window.location.href = "https://www.backdrophome.com/pages/my-account"
      }
    },
    onError: x => {
      window.location.href = "https://www.backdrophome.com/pages/my-account"
    },
  })

  return (
    <Layout>
      <SEO title="Industry by Backdrop - Set your password" />
      {loading ? (
        <Box
          align="center"
          justify="center"
          pad="large"
          width="medium"
          margin={{ vertical: "large", horizontal: "auto" }}
          gap="large"
        ></Box>
      ) : (
        <Box
          align="center"
          justify="center"
          pad="large"
          width="medium"
          margin={{ vertical: "large", horizontal: "auto" }}
          gap="large"
        >
          {reset && <Heading margin="none">Reset password</Heading>}
          {firstName && <Heading margin="none">Hi, {firstName}!</Heading>}
          <Paragraph textAlign="center">
            {reset
              ? "Set a new password for your Industry by Backdrop account."
              : `In order to finish setting up your Industry by Backdrop account,
            please set a password.`}
          </Paragraph>
          <Box fill>
            <FormResetPassword resetPasswordToken={token} />
          </Box>
        </Box>
      )}
    </Layout>
  )
}

export default withLocation(SetPasswordPage)
